import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';

// angular trackby function
export function trackByBookId(index: number, item: any) {
  return item.bookId;
}


export function unique<T>(array: T[] = [], prop: keyof T): T[] {
  const result: T[] = [];
  const map = new Map();
  for (const item of array) {
    const key = item[prop];
    if (!map.has(key)) {
      map.set(key, true); // set any value to Map
      result.push(item);
    }
  }
  return result;
}

export function groupBy<T>(array: T[] = [], prop: keyof T): Record<string, T[]> {
  return array.reduce((groups: Record<string, T[]>, item: T) => {
    const val = String(item[prop]);
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
}

@Injectable({ providedIn: 'root' })
export class UniversalService {
  platformId = inject(PLATFORM_ID);
  document = inject(DOCUMENT);

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  isServer() {
    return isPlatformServer(this.platformId);
  }
}

export function isBrowser() {
  return inject(UniversalService).isBrowser();
}
